<template>
  <div
    :class="[
      'container-' + organism.containerWidth,
      'relative overflow-hidden bg-gray-100 organism simple-centered',
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <img
      class="absolute inset-0 object-cover w-full h-full organism wysiwyg-block opacity-10"
      :src="getImageSrc(organism.backgroundImage, 'large')"
      :style="'opacity:' + organism.backgroundImageOpacity"
      loading="lazy"
    />
    <div
      class="relative content py-12 sm:py-28 max-w-[608px] flex items-center justify-center flex-col text-center mx-auto"
    >
      <h2
        class="text-[2rem] leading-10 font-semibold text-gray-900 sm:text-6xl title-1 font-cg"
      >
        {{ organism.title1 }}
      </h2>
      <h2
        class="mb-3 text-[2rem] leading-10 font-semibold sm:text-6xl title-2 font-cg text-fr-secondary-dark"
      >
        {{ organism.title2 }}
      </h2>
      <div
        class="text-xl text-gray-500 font-figtree"
        v-html="organism.text1"
      ></div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
